<template>
  <div>
    <img class="banner d-lg-block d-none" src="~assets/images/img/contactBanner.jpg"/>
    <div class="about container-fluid">
      <div class="row">
        <div class="col-12 col-lg-2" style="padding: 0">
          <switch-bar :switchList="switchList"
                      @switchItem="switchItem"
                      :activeIndex="Number(activeIndex)"/>
        </div>
        <div class="col-12 col-lg-9 aboutRight">
          <div class="rightTitle">
            <div class="rightTitleValue d-lg-block d-flex align-items-center">
              <p class="rightTitleValueC">{{showTitle}}</p>
              <p class="rightTitleValueE">{{showTitleE}}</p>
            </div>
            <div class="rightTitleLinks d-none d-lg-flex">
              <p>当前位置：</p>
              <router-link class="link" to="/home" tag="p">首页</router-link>
              >
              <!--刷新页面并且更新模块为第一块-->
              <router-link class="link" :to="{path:switchList.link,query:{index:0}}" tag="p">{{switchList.text}}
              </router-link>
              >
              <p class="linkLast">{{showTitle}}</p>
            </div>
          </div>
          <!--内容块-->
          <div class="WapBoxPadding contactManner" v-show="activeIndex==0">
            <div class="contact row" v-if="contactInfo">
            <div class="contactBox col-12" v-for="item in contactInfo">
              <p class="contactBoxText">{{item.title}}</p>
              <p class="contactBoxValue">电话：{{item.contact_telephone}}</p>
              <p class="contactBoxValue">传真：{{item.contact_fax}}</p>
              <p class="contactBoxValue">地址：{{item.contact_adress}}</p>
            </div>

         <!--     <div class="contactMannerBox col-12 col-md-4 col-lg-4">
                <img class="contactMannerBoxIcon" src="~assets/images/icon/contactUs1.png"/>
                <p class="contactMannerBoxValue">{{contactInfo.contact_adress}}</p>
              </div>
              <div class="contactMannerBox col-12 col-md-4 col-lg-4">
                <img class="contactMannerBoxIcon" src="~assets/images/icon/contactUs2.png"/>
                <p class="contactMannerBoxValue">{{contactInfo.contact_telephone}}</p>
              </div>
              <div class="contactMannerBox col-12 col-md-4 col-lg-4">
                <img class="contactMannerBoxIcon" src="~assets/images/icon/contactUs3.png"/>
                <p class="contactMannerBoxValue">{{contactInfo.contact_fax}}</p>
              </div>-->



            </div>
            <contact-map :mapInfo="mapInfo" :info="contactInfo[0]"
                         v-if="mapInfo.location.lng!=''&&mapInfo.location.lat!=''"/>
          </div>
          <div class="WapBoxPadding contactFromCoat" v-show="activeIndex==1">
            <p class="contactFromTitle">非常感谢您对天润国际的帮助与支持，为了更好地统一服务标准，增强客户体验，我们随时欢迎您留下任何建议和意见。</p>
            <form class="contactFrom">
              <input class="contactFromInput" placeholder="请输入您的名字" v-model="contactForm.name"/>
              <input class="contactFromInput" type="number" placeholder="请输入您的电话" v-model="contactForm.phone"/>
              <input class="contactFromInput" placeholder="请输入您的邮箱" v-model="contactForm.email"/>
              <input class="contactFromInput" placeholder="请输入您的公司" v-model="contactForm.company"/>
              <textarea class="contactFromTextarea" placeholder="请输入您的咨询或留言" v-model="contactForm.enquiry"></textarea>
              <div class="contactFromButCoat" @click="formSubmit"><p class="contactFromBut">发送 →</p></div>
            </form>
          </div>
          <!--内容块 END-->
        </div>
      </div>
    </div>
    <!--弹窗-->
    <pop-ups :popUpsValue="popUpsValue" :showPopUps="popUpsStatus" @popUps="popUps"/>
  </div>
</template>
<script>
  import SwitchBar from "../../components/content/SwitchBar";
  import ContactMap from "../../components/content/ContactMap";
  import {getContact} from 'network/contact';
  import axios from 'axios';
  import PopUps from "./childcomponents/PopUps";

  export default {
    name: "ContactUs",
    components: {PopUps, ContactMap, SwitchBar},
    data() {
      return {
        popUpsValue: '',
        popUpsStatus: false,
        //数据
        contactInfo: null,
        //form表单数据
        contactForm: {
          name: '',
          phone: '',
          email: '',
          company: '',
          enquiry: '',
        },
        //当前活跃的块下标
        activeIndex: 0,
        info: {
          title: '天润',
          address: '福建省泉州市石狮市锦尚镇锦尚二区90号',
          linktel: '0595-88859567',
          phone_number: '0595-88859567',
        },
        mapInfo: {
          location: {
            lng: '',
            lat: ''
          },
          zoom: 16
        },
      }
    },
    methods: {
      popUps() {
        this.popUpsStatus = false;
      },


      switchItem(index) {
        if (index != this.$route.query.index) {
          this.$router.push({
            path: this.$route.path,
            query: {
              index: index
            }
          })
        }
      },
      formSubmit() {
        const formInfo = this.contactForm;
        console.log(formInfo);
        if (formInfo.name == '') {
          this.popUpsValue = '请输入您的姓名';
          this.popUpsStatus = true;
        } else if (!(/^1[34578]\d{9}$/.test(formInfo.phone))) {
          this.popUpsValue = '请填写正确的手机号';
          this.popUpsStatus = true;
        } else if (!(/^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/.test(formInfo.email))) {
          this.popUpsValue = '请填写正确的邮箱号';
          this.popUpsStatus = true;
        } else if (formInfo.company == '') {
          this.popUpsValue = '请填写您的公司名称';
          this.popUpsStatus = true;
        } else if (formInfo.enquiry == '') {
          this.popUpsValue = '请填写留言信息';
          this.popUpsStatus = true;
        } else {
          // this.contactForm.route = 'information/contact/onlineContact';
          console.log(this.contactForm);
          axios({
            url: '/index.php?route=information/contact/onlineContact',
            method: 'get',
            params: this.contactForm,
          }).then(res => {
            // console.log(res, this.contactForm);
            if (res.data.success==1){
              this.popUpsValue = '留言成功';
            }else {
              this.popUpsValue = '留言失败,请重新提交';
            }
            this.popUpsStatus = true;
          })
        }
      },
    },
    computed: {
      switchList() {
        //从vuex中取出对应的数据 ==>store ==>index
        return this.$store.state.navInfo[6];
      },
      // 当前活跃块的中文标题
      showTitle() {
        return this.switchList.childList[this.activeIndex].title
      },
      // 当前活跃块的英文标题
      showTitleE() {
        return this.switchList.childList[this.activeIndex].titleE
      },
    },
    created() {
      this.activeIndex = this.$route.query.index;
      getContact().then(res => {
        // console.log(res.data.contact_nei);
        this.contactInfo = res.data.contact_nei;
        this.mapInfo.location.lng = res.data.contact_zbiao.contact_zbiao_x;
        this.mapInfo.location.lat = res.data.contact_zbiao.contact_zbiao_y;
      })
    },
    watch: {
      '$route'() {
        this.activeIndex = this.$route.query.index;
      }
    }
  }
</script>
<style scoped>
  /* 小屏幕（平板，大于等于 768px） */
  @media (max-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .6rem 3%;
      border-bottom: 1px solid #e2e2e2;
    }

    .WapBoxPadding {
      padding: 0 3%;
    }
  }

  /* 中等屏幕（桌面显示器，大于等于 992px） */
  @media (min-width: 992px) {
    .contactFromInput:nth-child(odd) {
      margin-right: 1.8%;
    }

    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: .35rem;
      border-bottom: 1px solid #e2e2e2;
    }

    .rightTitleValueC {
      margin-bottom: .2rem;
    }
  }
</style>
<style scoped>
  .contactFromTitle {
    font-size: .4rem;
    color: #999999;
    margin-bottom: .6rem;
  }

  .contactFrom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .contactFromInput {
    width: 49%;
    padding: .3rem;
    border: 1px solid #ededed;
    background-color: #f5f5f5;
    font-size: .35rem;
    color: #8c8c8c;
    margin-bottom: .6rem;
  }

  .contactFromTextarea {
    width: 100%;
    padding: .3rem;
    border: 1px solid #ededed;
    background-color: #f5f5f5;
    font-size: .35rem;
    color: #8c8c8c;
    margin-bottom: .6rem;
    height: 5rem;
  }

  .contactFromButCoat {
    width: 100%;
    text-align: end;
  }

  .contactFromBut {
    padding: .15rem 1rem;
    border: 1px solid var(--red);
    color: var(--red);
    border-radius: 30px;
    background-color: #fff;
    display: inline-block;
    font-size: .4rem;
    cursor: pointer;
  }

  .contactFrom * ::placeholder{
    color: #999;
  }



  .contactMannerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.4rem;
  }

  .contactMannerBoxIcon {
    height: 1.25rem;
    width: auto;
    margin-bottom: .6rem;
  }

  .contactMannerBoxValue {
    font-size: .35rem;
    color: #8c8c8c;
    text-align: center;
  }

  .banner {
    width: 100%;
    height: auto;
  }

  .about {
    padding: 1.65rem var(--switchMarginX);
    margin-bottom: .7rem;
  }

  .rightTitle {
    margin-bottom: .55rem;
  }

  .rightTitleValueC {
    font-size: .6rem;
    color: #474747;
    margin-right: .4rem;
  }

  .rightTitleValueE {
    font-size: .35rem;
    color: #b2b2b2;
    padding: 0;
  }

  .rightTitleLinks {
    display: flex;
    align-items: center;
    font-size: .35rem;
    color: #b2b2b2;
    cursor: pointer;
  }

  .rightTitleLinks .link:hover {
    color: var(--red);
    transition: color .3s;
  }

  .rightTitleLinks .linkLast {
    color: #737373;
  }
  .contactBox{
    padding: 1.5rem .9rem;
    background-color: #f9f9f9;
    margin-bottom: 1rem;
  }
  .contactBoxValue{
    color: #7f7f7f;
    font-size: .35rem;
    line-height: 30px;
  }
  .contactBoxText{
    color: #474747;
    font-weight: bold;
    font-size: .6rem;
    margin-bottom: .75rem;
  }
</style>