<template>
  <div>
    <div class="popUps" @click="popUps" v-show="show">
      <div class="popUpsBox">
        <p class="popUpsOff">提示信息</p>
        <div class="popUpsValue">{{popUpsValue}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "PopUps",
    props: {
      popUpsValue: {
        type: String,
        defaults: '信息',
      },
      showPopUps: {
        type: Boolean,
        defaults: false
      },
    },
    data(){
      return {
        show:false
      }
    },
    watch: {
      showPopUps(news) {
        this.show=news;
      }
    },
    methods: {
      popUps() {
        this.$emit('popUps');
        this.show = false;
      },
    },
  }
</script>

<style scoped>
  .popUps {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, .2);
    display: flex;
    justify-content: center;
  }

  .popUpsBox {
    margin-top: 20%;
    width: 10rem;
    border-radius: 10px;
  }

  .popUpsOff {
    border-radius: 10px 10px 0 0;
    background-color: var(--red);
    padding: .2rem .4rem;
    font-size: .4rem;
    color: #fff;
    text-align: center;
    cursor: pointer;
  }

  .popUpsValue {
    font-size: .6rem;
    color: #0371a2;
    padding: .5rem .4rem;
    text-align: center;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
  }
</style>