<template>
  <div class="map" v-if="mapInfo">
    <baidu-map :center="mapInfo.location" :zoom="mapInfo.zoom" :scroll-wheel-zoom="true">
      <bm-info-window v-if="info"
                      class="bm-info-window"
                      :position="mapInfo.location"
                      :show="show"
                      @close="infoWindowClose"
                      @open="infoWindowOpen">
        <p class="title">{{info.title}}</p>
        <p class="info">地址：{{info.contact_adress}}</p>
        <p class="info">电话：{{info.contact_fax}}</p>
        <p class="info">传真：{{info.contact_telephone}}</p>
      </bm-info-window>
      <bm-marker v-if="mapInfo.location.lat&&mapInfo.location.lng" :position="mapInfo.location" :dragging="true"  animation="BMAP_ANIMATION_BOUNCE"/>
      <bm-view style="width: 100%; height: 7rem;flex: 1;"/>
      <bm-local-search/>
    </baidu-map>
  </div>
</template>

<script>
  export default {
    name: "ContactMap",
    data() {
      return {
        show: true,
      }
    },
    props: {
      mapInfo: {
        type: Object,
        default() {
          return null
        }
      },
      info: {
        type: Object,
        default() {
          return null
        }
      },
    },
    computed:{

    },
    methods: {
      infoWindowClose(e) {
        this.show = false
      },
      infoWindowOpen(e) {
        this.show = true
      },
    }
  }
</script>

<style>
  .bm-info-window .title {
    font-size: .5rem;
    margin-bottom: .1rem;
  }

  .bm-info-window .info {
    font-size: .36rem;
    color: #666666;
  }
</style>
<style scoped>
  .bm-info-window {
    font-size: .6rem;
  }
</style>